import { ReactNode, useEffect, useState } from "react";

/**
 * For anything that has an isOpen prop, we normally want to render the html
 * of that compoent, and _then_ update the isOpen prop.  This component
 * encapsulates that pattern.
 */
export const DeferredOpen = ({
  isOpen: isOpenProp,
  children
}: {
  isOpen: boolean;
  children: (deferredOpen: boolean) => ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  // Render the children if we're requested to show them, then
  // let the children function get called with isOpen = true for a second time once
  // the use-effect actually opens the children.
  //
  // Basically, render the HTML for a modal, but tell it not to show children
  // then let the use-effect render the children.  This preserves the open transitions
  // but we need something more complicated to preserve closing transitions.
  return isOpenProp ? children(isOpen) : null;
};