import MincaLink from "components/common/MincaLink";
const NotFound = ({
  href = "/pending"
}: {
  href?: string;
}) => {
  return <div className="flex justify-center items-left w-full flex-col max-w-md" data-sentry-component="NotFound" data-sentry-source-file="NotFound.tsx">
      <div className="p-4 mb-8 text-center">
        <h3 className="font-bold">Not Found</h3>
        <p className="text-base">Could not find requested resource</p>
      </div>
      <MincaLink type="button" className="text-center flex mx-auto" href={href} data-sentry-element="MincaLink" data-sentry-source-file="NotFound.tsx">
        Go Home
      </MincaLink>
    </div>;
};
export default NotFound;