import Decimal from "decimal.js-light";
import { safeDecimal } from "utils";
import type { Replace } from "./helper-types";
import { AnyObject, isObject } from "./is_object";

export const mapValues = <V, T extends AnyObject, E>(
  initial: T,
  transform: <K extends keyof T>(key: K, value: T[K]) => E
) => {
  const entries = Object.entries(initial).map(([k, v]) => [k, transform(k, v)]);
  const res = Object.fromEntries(entries) as { [K in keyof T]: E };
  return res;
};

export function sumAllAsDecimal<
  T extends Record<string, Decimal | string | null | undefined>,
>(arr: T[]): { [K in keyof T]: Decimal } {
  const keys = uniqueKeys(...arr);
  const init = Object.fromEntries(keys.map((k) => [k, safeDecimal("0.00")]));

  const result = arr.reduce((accum, e) => {
    const sums = keys.map((k) => {
      const p = safeDecimal(accum[k]);
      const n = safeDecimal(e[k]);
      return [k, p.plus(n)] as [string, Decimal];
    });

    return Object.fromEntries(sums);
  }, init);

  return result as { [K in keyof T]: Decimal };
}

/**
 * Given N number of objects, return the unique keys included.
 */
export function uniqueKeys<T extends { [key: string]: unknown }>(...arr: T[]) {
  return arr.reduce((accum, elem) => {
    Object.keys(elem).forEach((k) => {
      if (accum.indexOf(k) === -1) {
        accum.push(k);
      }
    });

    return accum;
  }, [] as string[]);
}

export const objectLength = (obj: AnyObject) => Object.keys(obj).length;

export function pick<
  T extends AnyObject,
  K extends keyof T & string, // Props we're keeping as-is
>(obj: T, ...keys: K[]): Pick<T, K> {
  const entries = keys.map((k) => {
    return [k, obj[k]];
  });

  return Object.fromEntries(entries);
}

export function getPropertyAsString(
  o: any,
  property: string,
  fallback: string
) {
  if (isObject(o) && property in o && typeof o[property] === "string") {
    return o[property];
  }

  return fallback;
}

export function castColumnType<
  O extends AnyObject[],
  FIELD extends keyof O[number] & string,
  FIELD_TYPE,
>(obj: O) {
  return obj as any as Replace<O[number], FIELD, FIELD_TYPE>[];
}

// Any values that are undefined in obj will be removed.  The type
// will remain the same.
export function removeUndefinedValues<T extends AnyObject>(obj: T): T {
  const entries = Object.entries(obj);
  const filtered = entries.filter(([k, v]) => v !== undefined);

  return Object.fromEntries(filtered) as T;
}

export function recordFromEntries<K extends string, V>(
  pairs: [K, V][]
): Record<K, V> {
  return Object.fromEntries(pairs) as Record<K, V>;
}

export function mapAsPairs<OBJ extends AnyObject, O>(
  initial: OBJ,
  transform: <K extends keyof OBJ>(key: K, value: OBJ[K]) => O
) {
  const results = Object.entries(initial).map(([k, v]) => transform(k, v));
  return results;
}
