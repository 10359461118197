export type AnyObject = { [key: string | number | symbol]: any };

export function isObject(o: any): o is AnyObject {
  if (o === null || o === undefined) {
    return false;
  }

  if (typeof o === "object" && !Array.isArray(o)) {
    return true;
  }

  return false;
}

export function hasProperty<K extends string>(
  o: any,
  property: K
): o is { [FK in K]: any } {
  if (!isObject(o)) {
    return false;
  }

  if (property in o) {
    return true;
  }

  return false;
}

type InferredPropertyType<
  K extends string,
  TP extends "string" | "object" | "number" | "boolean" | "array",
  O extends boolean = false, // If this is optional
> = {
  [FK in K]:
    | (TP extends "string"
        ? string
        : TP extends "object"
          ? AnyObject
          : TP extends "number"
            ? number
            : TP extends "boolean"
              ? boolean
              : TP extends "array"
                ? any[]
                : never)
    | (O extends true ? undefined : NonNullable<undefined>);
};

/**
 * if `optional` is true, then N can be
 */
export function hasPropertyOfType<
  K extends string,
  TP extends "string" | "object" | "number" | "boolean" | "array",
  O extends boolean = false,
>(
  o: any,
  property: K,
  typeofProperty: TP,
  { optional = false as O }: { optional: O } = { optional: false as O }
): o is InferredPropertyType<K, TP, O> {
  if (!isObject(o)) {
    return false;
  }

  if (!(property in o)) {
    return optional;
  }

  if (typeofProperty === "array" && Array.isArray(o[property])) {
    return true;
  }

  if (typeofProperty === "object" && isObject(o[property])) {
    return true;
  }

  return typeof o[property] === typeofProperty;
}

/**
 * Always let the generics be inferred with this
 */
export function hasPropertyLiteral<
  K extends string,
  LV extends string | number | true | false | null | undefined,
>(
  o: any,
  property: K,
  ...literalValues: LV[] & (string | number | true | false | null | undefined)[]
): o is {
  [FK in K]: LV;
} {
  if (!isObject(o)) {
    return false;
  }

  if (!(property in o)) {
    return literalValues.includes(undefined);
  }

  if (o[property] === null) {
    return literalValues.includes(null);
  }

  if (o[property] === undefined) {
    return literalValues.includes(undefined);
  }

  return literalValues.includes(o[property]);
}
