import {
  SelectablePurchaseOrder,
  type PurchaseOrderContextRecord,
  type PurchaseOrderWithProfileDetails,
} from "@database/queries/purchase_order";
import Decimal from "decimal.js-light";
import camelCase from "lodash.camelcase";
import transform from "lodash.transform";
import { DateTime } from "luxon";
import { NextApiResponse } from "next";
import { ZodError } from "zod";
import { fromZodError } from "zod-validation-error";
import { isBlank, isPresent } from "./blank";
import { OPERATING_TIMEZONE, daysBetween } from "./dates";
import { nullish } from "./helper-types";
import { AnyObject, isObject } from "./is_object";

export function defined<T>(obj: T | null | undefined): obj is T {
  return obj !== null && obj !== undefined;
}

export const camelize = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (
      result: Record<string, unknown>,
      value: unknown,
      key: string,
      target: any
    ) => {
      const camelKey = Array.isArray(target) ? key : camelCase(key);
      result[camelKey] = isObject(value)
        ? camelize(value as Record<string, unknown>)
        : value;
    }
  );

const sentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getStateCodeFromStateName = (stateName: string) => {
  if (!stateName) return null;
  stateName = sentenceCase(stateName);
  switch (stateName) {
    case "Alabama":
      return "AL";
    case "Alaska":
      return "AK";
    case "Arizona":
      return "AZ";
    case "Arkansas":
      return "AR";
    case "California":
      return "CA";
    case "Colorado":
      return "CO";
    case "Connecticut":
      return "CT";
    case "Delaware":
      return "DE";
    case "District Of Columbia":
      return "DC";
    case "Florida":
      return "FL";
    case "Georgia":
      return "GA";
    case "Hawaii":
      return "HI";
    case "Idaho":
      return "ID";
    case "Illinois":
      return "IL";
    case "Indiana":
      return "IN";
    case "Iowa":
      return "IA";
    case "Kansas":
      return "KS";
    case "Kentucky":
      return "KY";
    case "Louisiana":
      return "LA";
    case "Maine":
      return "ME";
    case "Maryland":
      return "MD";
    case "Massachusetts":
      return "MA";
    case "Michigan":
      return "MI";
    case "Minnesota":
      return "MN";
    case "Mississippi":
      return "MS";
    case "Missouri":
      return "MO";
    case "Montana":
      return "MT";
    case "Nebraska":
      return "NE";
    case "Nevada":
      return "NV";
    case "New Hampshire":
      return "NH";
    case "New Jersey":
      return "NJ";
    case "New Mexico":
      return "NM";
    case "New York":
      return "NY";
    case "North Carolina":
      return "NC";
    case "North Dakota":
      return "ND";
    case "Ohio":
      return "OH";
    case "Oklahoma":
      return "OK";
    case "Oregon":
      return "OR";
    case "Pennsylvania":
      return "PA";
    case "Rhode Island":
      return "RI";
    case "South Carolina":
      return "SC";
    case "South Dakota":
      return "SD";
    case "Tennessee":
      return "TN";
    case "Texas":
      return "TX";
    case "Utah":
      return "UT";
    case "Vermont":
      return "VT";
    case "Virginia":
      return "VA";
    case "Washington":
      return "WA";
    case "West Virginia":
      return "WV";
    case "Wisconsin":
      return "WI";
    case "Wyoming":
      return "WY";
    default:
      return "";
  }
};

export const fileToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const createRandomAlphaNumericPassword = (length = 16) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const apiErrorHandler = (
  err: any,
  res: NextApiResponse,
  msg: string = "API Error: "
) => {
  console.error(msg, err);
  if (err instanceof ZodError) {
    return res.status(400).json(fromZodError(err));
  }

  return res.status(500).json({
    statusCode: 500,
    message: err.response?.data?.message || err.message,
  });
};

export const base64toBlob = (
  base64Data: string,
  contentType: string = "",
  sliceSize = 1024
): Blob => {
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const isLargePdf = (pdfBase64: string, maxSize: number) => {
  const buffer = Buffer.from(pdfBase64, "base64");
  const sizeInMB = buffer.byteLength / 1024 / 1024;
  return sizeInMB > maxSize;
};

export const classNames = (...classes: (string | undefined | null)[]) => {
  return classes.filter(Boolean).join(" ");
};

/**
 * Helper function for providing default class names, but letting the prop choose to disable
 * the helpers by including the keyword "reset-class-names" as a class name.
 */
export const classNamesWithReset = (
  defaultClassNames: string,
  classNamesProp: string | undefined | null
) => {
  if (!isPresent(classNamesProp)) {
    return defaultClassNames;
  }

  if (classNamesProp.includes("reset-class-names")) {
    return classNamesProp;
  }

  return `${defaultClassNames.trim()} ${classNamesProp.trim()}`.trim();
};

export const formatCurrency = (amount: number) => {
  if (isNaN(amount)) {
    console.error(`Invalid amount: ${amount}`);
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

export const formatDate = (date: string) => {
  const formattedDate = new Date(date);
  if (isNaN(formattedDate.getTime())) {
    console.error(`Invalid date string: ${date}`);
    return "";
  }
  const month = formattedDate.getMonth() + 1;
  const day = formattedDate.getDate();
  const year = formattedDate.getFullYear();

  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;
};

type CalculateFundingAndDailyFeeInput = {
  invoiceAmount: Decimal;
  paidAmount?: Decimal;
  daysFactorOpen: Decimal; // (advanced on 5/1, and then closed on 5/2 would be 1)
  advancePercentage?: Decimal; // The percentage of the invoice amount that is being requested
  dailyFeePercentage?: Decimal; // The daily fee expressed as a percentage of the total invoice value
  minimumFee?: Decimal; // The minimum fee that we charge
  floatDays?: Decimal;
  mincaFee?: Decimal;
};

type CalculateFundingAndDailyFeeInputWithDates = {
  invoiceAmount: Decimal;
  paidAmount?: Decimal;
  dateFactorOpened: DateTime;
  dateClosed: DateTime;
  mincaFee?: Decimal;
  advancePercentage?: Decimal; // The percentage of the invoice amount that is being requested
  dailyFeePercentage?: Decimal; // The daily fee expressed as a percentage of the total invoice value
  minimumFee?: Decimal; // The minimum fee that we charge
  floatDays?: Decimal;
};

export type CalculateFundingAndDailyFeeOutput = {
  eligibleFundingAmount: Decimal;
  dailyFee: Decimal;
  accruedFee: Decimal;
  totalFee: Decimal;
  escrowAmount: Decimal;
  closingAmount: Decimal;
  shortPayment: Decimal;
  daysFactorOpen: Decimal;
  minimumFee: Decimal;
};

export const calculateFundingAndDailyFee = ({
  invoiceAmount,
  paidAmount,
  advancePercentage = new Decimal("80.00"),
  dailyFeePercentage = new Decimal("0.05"),
  floatDays = decimal("0"),
  minimumFee = new Decimal("50.00"),
  mincaFee,
  ...dateInfo
}:
  | CalculateFundingAndDailyFeeInput
  | CalculateFundingAndDailyFeeInputWithDates): CalculateFundingAndDailyFeeOutput => {
  if (!floatDays.isInteger()) {
    throw "floatDays must be an integer";
  }

  const daysFactorOpen =
    "daysFactorOpen" in dateInfo
      ? dateInfo.daysFactorOpen
      : decimal(daysBetween(dateInfo.dateFactorOpened, dateInfo.dateClosed));

  if (!daysFactorOpen.isInteger()) {
    throw "daysSaleOutstanding must be an integer";
  }

  minimumFee = minimumFee.todp(2, Decimal.ROUND_HALF_UP);

  const eligibleFundingAmount = invoiceAmount
    .times(advancePercentage.dividedBy("100.00"))
    .todp(2, Decimal.ROUND_HALF_UP);

  const dailyFee = dailyFeePercentage
    .dividedBy("100.00")
    .times(invoiceAmount)
    .todp(2, Decimal.ROUND_HALF_UP);

  const accruedFee = dailyFee
    .times(daysFactorOpen)
    .todp(2, Decimal.ROUND_HALF_UP);

  const totalFee = mincaFee
    ? mincaFee
    : accruedFee.gt(minimumFee)
      ? accruedFee
      : minimumFee;

  const escrowAmount = invoiceAmount.minus(eligibleFundingAmount);
  paidAmount = paidAmount || invoiceAmount;
  const closingAmount = paidAmount.minus(totalFee).minus(eligibleFundingAmount);

  return {
    eligibleFundingAmount,
    dailyFee,
    totalFee,
    accruedFee,
    escrowAmount,
    closingAmount,
    shortPayment: invoiceAmount.minus(paidAmount),
    daysFactorOpen,
    minimumFee,
  };
};

export const PurchaseOrderActions = (
  po:
    | SelectablePurchaseOrder
    | PurchaseOrderContextRecord
    | PurchaseOrderWithProfileDetails
) => {
  const secondsSinceFundingRequested =
    po.factorRequestedAt && !po.factorClosedOn
      ? DateTime.fromJSDate(po.factorRequestedAt)
          .setZone(OPERATING_TIMEZONE)
          .diffNow("seconds").seconds * -1
      : undefined;

  return {
    // Draft or Under Review payapps
    canDelete:
      (!po.submittedForInternalReviewAt && !po.factorClosedOn) ||
      (!po.submittedToAgencyAt && !!po.submittedForInternalReviewAt),

    // Draft, or Needs Resubmission.
    canEdit:
      (!po.submittedForInternalReviewAt ||
        !!po.resubmissionRequestedAt ||
        po.contractorUnsubmittedAt) &&
      !po.factorClosedOn,

    // Draft or Needs Attention payapps
    canSubmitToMinca:
      (!po.submittedForInternalReviewAt ||
        !!po.resubmissionRequestedAt ||
        po.contractorUnsubmittedAt) &&
      !po.factorClosedOn,

    // For POs without an agency workflow, po.approvalIsComplete will be null.
    // For those, once Minca has approved the submission cannot be canceled.
    // Otherwise, once the approvalIsComplete the submission cannot be canceled.
    canCancelSubmission:
      !po.contractorUnsubmittedAt &&
      po.submittedForInternalReviewAt &&
      !po.resubmissionRequestedAt &&
      ((po.approvalIsComplete === null && !po.mincaApprovedAt) ||
        po.approvalIsComplete === false),

    canRequestFunding:
      !!po.submittedToAgencyAt && !po.factorRequestedAt && !po.factorClosedOn,

    canCancelFunding: !secondsSinceFundingRequested
      ? null
      : secondsSinceFundingRequested <= 5 * 60
        ? ("immediate" as const)
        : ("request" as const),

    agencyCanCreateApproval:
      po.submittedForInternalReviewAt && !po.contractorUnsubmittedAt,
  };
};

export const formatMoney = (
  money: string | number | null | undefined | Decimal,
  fallback = "",
  includeDollarSign = true
) => {
  if (!money) {
    return fallback;
  }

  if (typeof money === "string" && isBlank(money)) {
    return fallback;
  }

  const asDecimal = decimal(money);
  const negativeSign = asDecimal.isNegative() ? "-" : "";
  const strDecimal = asDecimal
    .absoluteValue()
    .toFixed(2, Decimal.ROUND_HALF_UP);
  const [dollars, cents] = strDecimal.split(".");
  const dollarStr = dollars
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)!
    .join(",")
    .split("")
    .reverse()
    .join("");

  const dispDollarSign = includeDollarSign ? "$" : "";

  return `${negativeSign}${dispDollarSign}${dollarStr}.${cents}`;
};

export const removeAllMoneySymbols = (moneyStr: string) => {
  return moneyStr.replaceAll("$", "").replaceAll(",", "");
};

export const MONEY_REGEX = /^-?\$?[0-9,]*\.?[0-9]{0,2}$/;

// Leaveing all this here because at some point, we'll need to update this, and converting between RegExp#source
// and an HTMLPattern has a lot of weird stuff things to catch.  They're the same engine but because of where
// they get rendered on a page, their syntax is a bit different.

// export const MONEY_PATTERN = "^\\$?[0-9,]*\\.?[0-9]{0,2}$";
// ^\$?[0-9,]*\.?[0-9]{0,2}$

// export const MONEY_PATTERN = MONEY_REGEX.source
// ^\$?[0-9\,]*\.?[0-9]{0,2}$

// This is the right one.
export const MONEY_PATTERN = MONEY_REGEX.source.replace("\\,", ",");
// ^\$?[0-9,]*\.?[0-9]{0,2}$

export const validateMoneyFormat = (raw: string) => MONEY_REGEX.test(raw);

export const pretty = (o: any, indentation = 2) => {
  return JSON.stringify(o, null, indentation);
};

export const safeDecimal = (
  coercable: string | number | Decimal | bigint | null | undefined,
  fallback: string | number | Decimal | bigint = "0.00"
) => {
  if (coercable === null || coercable === undefined) {
    return decimal(fallback);
  }

  try {
    return decimal(coercable);
  } catch {
    return decimal(fallback);
  }
};

export const decimal = (coercable: string | number | Decimal | bigint) => {
  // remove any dollar-related characters
  if (typeof coercable === "string") {
    coercable = coercable.replace("$", "").replaceAll(",", "");
  }

  if (typeof coercable === "bigint") {
    coercable = coercable.toString();
  }

  return new Decimal(coercable);
};

export const undefinedIfNull = (val: string | undefined | null) => {
  if (val === null) {
    return undefined;
  }

  return val;
};

export type VoidFN = () => void;
export const noop: VoidFN = () => undefined;

export function parseBoolean(maybeBoolean: nullish<string>): boolean {
  if (maybeBoolean === "true") {
    return true;
  } else if (maybeBoolean === "false") {
    return false;
  }

  throw `Could not parse boolean value from ${maybeBoolean}`;
}

export function insertUpdateDeleteStateSetter<
  T extends { id: number | string },
>(
  stateSetter: (updater: (existing: T[]) => T[]) => void,
  updated: T,
  eventType: "UPDATE" | "INSERT" | "DELETE"
) {
  switch (eventType) {
    case "UPDATE":
      stateSetter((existingSet) =>
        existingSet.map((existing) => {
          if (existing.id === updated.id) {
            return updated;
          } else {
            return existing;
          }
        })
      );
      break;
    case "DELETE":
      stateSetter((existingSet) =>
        existingSet.filter((existing) => existing.id !== updated.id)
      );
      break;
    case "INSERT":
      stateSetter((existingSet) => [updated, ...existingSet]);
      break;
    default:
      break;
  }
}

export function containsAny(
  target: { [key: string]: unknown },
  needles: string[]
) {
  const keys = Object.keys(target);
  for (const needle of needles) {
    if (keys.includes(needle)) {
      return true;
    }
  }

  return false;
}

export const downloadFile = async (url: string): Promise<ArrayBuffer> => {
  const resp = await fetch(url);
  const buffer = await resp.arrayBuffer();
  return buffer;
};

// Given a string uuid, display only the last section
export const shortUUIDDisplay = (uuid: string) => uuid.slice(-12);

export const lastUuidSegment = (uuid: string, fallback: string = "") => {
  const val = uuid.split("-").slice(-1)[0];
  return isPresent(val) ? val : fallback;
};

/**
 * Removes any keys from the object whos values are undefined.
 * It's recursive.
 */
export function removeUndefinedValuesDeep<T extends AnyObject>(obj: T): T {
  const entries = Object.entries(obj);
  const filtered = entries.filter(([k, v]) => v !== undefined);

  const updatedEntries = filtered.map(([k, v]) =>
    v !== null && typeof v === "object"
      ? [k, removeUndefinedValuesDeep(v)]
      : [k, v]
  );

  return Object.fromEntries(updatedEntries);
}

export function sumDecimals(
  input: (string | Decimal | null | undefined)[]
): Decimal {
  return input.reduce<Decimal>((accum, next) => {
    const dNext = next ? decimal(next) : decimal("0.00");
    return accum.plus(dNext);
  }, decimal("0.00"));
}

export function averageNumbers(input: (string | Decimal | number)[]): Decimal {
  if (input.length === 0) {
    return decimal(0.0);
  }
  return input
    .reduce<Decimal>((accum, next) => {
      return accum.plus(decimal(next));
    }, decimal("0.00"))
    .div(input.length);
}

export async function encrypt(value: string, key: string) {
  // convert key and value into byte array
  const keyBytes = new TextEncoder().encode(key);
  const valueBytes = new TextEncoder().encode(value);

  // use key to encrypt value and return it as a base64 string
  return crypto.subtle
    .importKey("raw", keyBytes, "AES-CBC", false, ["encrypt"])
    .then((encyptedKey) => {
      return crypto.subtle
        .encrypt(
          { name: "AES-CBC", iv: new Uint8Array(16) },
          encyptedKey,
          valueBytes
        )
        .then((encryptedBytes) => {
          return btoa(
            String.fromCharCode.apply(
              null,
              Array.from(new Uint8Array(encryptedBytes))
            )
          );
        });
    });
}

export async function decrypt(encryptedBase64: string, key: string) {
  const keyBytes = new TextEncoder().encode(key);
  // convert base64 string to byte array
  const encryptedBytes = new Uint8Array(
    atob(encryptedBase64)
      .split("")
      .map((c) => c.charCodeAt(0))
  );

  // use key to decrypt string
  return crypto.subtle
    .importKey("raw", keyBytes, "AES-CBC", false, ["decrypt"])
    .then((key) => {
      return crypto.subtle
        .decrypt(
          { name: "AES-CBC", iv: new Uint8Array(16) },
          key,
          encryptedBytes
        )
        .then((decryptedBytes) => {
          return new TextDecoder().decode(decryptedBytes);
        });
    });
}

export function mapRange<O>(
  start: number, // Inclusive
  end: number, // Exclusive
  onEach: (i: number) => O
) {
  const collected: O[] = [];

  for (let i = start; i < end; i++) {
    collected.push(onEach(i));
  }

  return collected;
}
