import { sortBy, type Comparator } from "./array";
import { mapValues } from "./object";

export function throwExpression(
  err: string | { message: string; error: string } | Error
): never {
  // TODO: We shouldn't be throwing strings, fix that.
  if (typeof err === "string") {
    throw {
      error: "Error",
      message: err,
    };
  }

  throw err;
}

/**
 * Given an array of items, group them by the result of the predicate.
 *
 * The predicate can return a group key, or an array of group keys.
 *
 * If returning an array of group keys, the target element will be added
 * to all the groups in the predicate.
 *
 * eg, returning: `[key1, key2]`, would result in the element in the target
 * element being added to the groiup for `key1` and for `key2`
 */
export function groupBy<T, O extends string | symbol | number>(
  items: T[],
  predicate: (input: T) => O | O[],
  options?: {
    sortGroupsBy?: Comparator<T>;
  }
): Record<O, Array<T>> {
  const result = {} as Record<O, Array<T>>;

  for (const row of items) {
    const groupKeys = [predicate(row)].flat() as O[];
    const groupArrays = groupKeys.map((groupKey) => result[groupKey]);

    groupArrays.forEach((groupArray, i) => {
      const groupKey = groupKeys[i];

      if (groupArray === undefined) {
        result[groupKey] = [row];
      } else {
        groupArray.push(row);
      }
    });
  }

  if (options?.sortGroupsBy) {
    return mapValues(result, (_, values) =>
      sortBy(values, options.sortGroupsBy!)
    );
  }

  return result;
}

export function omit<
  T extends { [K in keyof T]: T[K] },
  O extends string & keyof any,
>(input: T, ...toOmit: O[]): Omit<T, O> {
  const res: Partial<T> = { ...input };

  Object.keys(input).forEach((key) => {
    if (toOmit.includes(key as O)) {
      // @ts-ignore
      delete res[key];
    }
  });

  return res as Omit<T, O>;
}
