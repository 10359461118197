"use client";

import MincaCenteredLogo from "components/layout/MincaCenteredLogo";
import NotFound from "components/layout/NotFound";
export default function Page() {
  return <a href="/pending" className="hover:cursor-pointer" data-sentry-component="Page" data-sentry-source-file="not-found.tsx">
      <MincaCenteredLogo data-sentry-element="MincaCenteredLogo" data-sentry-source-file="not-found.tsx">
        <NotFound data-sentry-element="NotFound" data-sentry-source-file="not-found.tsx" />
      </MincaCenteredLogo>
    </a>;
}