type NestedArray<T> = Array<T | NestedArray<T>>;

// Call fn in depth-first search order of a nested array of Ts
export function flatForEach<T>(arr: NestedArray<T>, fn: (element: T) => void) {
  for (const e of arr) {
    if (Array.isArray(e)) {
      flatForEach(e, fn);
    } else {
      fn(e);
    }
  }
}

// Ensure element is in arr at least once.
export function setOnce<T>(arr: T[], element: T): T[] {
  if (arr.includes(element)) {
    return arr;
  }

  return [...arr, element];
}

export function lengthGt(arr: unknown[] | null | undefined, gt: number) {
  return !!(arr && arr.length > gt);
}

export type Comparator<T> = (a: T, b: T) => number;
export function sortBy<T>(arr: T[], compareFn: Comparator<T>) {
  const res = [...arr];
  res.sort(compareFn);
  return res;
}

/**
 * if arr is empty, returns a new array with just val in it.
 * Otherwise, appends val to arr in-place, and returns arr.
 */
export function createOrPushInPlace<T>(arr: T[] | undefined, val: T) {
  if (arr === undefined) {
    return [val];
  }

  arr.push(val);

  return arr;
}

/**
 * Same as createOrPushInPlace, but will not insert a null value.
 */
export function createOrPushInPlaceSkipNullish<T>(
  arr: T[] | undefined,
  val: T | null | undefined
): NonNullable<T>[] {
  if (val === null || val === undefined) {
    if (arr === undefined) {
      return [];
    }

    return arr as NonNullable<T>[];
  }

  if (arr === undefined) {
    return [val];
  }

  arr.push(val);

  return arr as NonNullable<T>[];
}

export function rejectNullish<T>(arr: T[]): NonNullable<T>[] {
  return arr.filter((r) => r !== null && r !== undefined) as NonNullable<T>[];
}

export function uniqueStrings(arr: string[]) {
  return [...new Set(arr).values()];
}

export type ArrayElem<T> = T extends Array<infer I> ? ArrayElem<I> : T;
export function flatten<T>(arr: (T | T[])[]): ArrayElem<T>[] {
  return arr.flat(Infinity) as ArrayElem<T>[];
}

export function swapValuesAtIndexesInPlace<T>(arr: T[], a: number, b: number) {
  const existing = arr[a];
  arr[a] = arr[b];
  arr[b] = existing;
}

export function isEmpty(arr: any[]) {
  return arr.length === 0;
}

/**
 * Type safe way to choose values from an array and get back the same type.
 *
 * Ideally, the compiler just inlines this out, but who knows what happens in reality.
 */
export function choose<T extends string | number | boolean>(
  arr: T[],
  ...values: T[]
) {
  return values;
}
