import { XMarkIcon } from "@heroicons/react/24/solid";
import { PageHeading } from "components/layout/page/DisplayComponents";
import React, { useLayoutEffect, type ReactNode } from "react";
import ReactModal from "react-modal";
import { classNames, classNamesWithReset } from "utils";
import { isPresent } from "utils/blank";
import { throwExpression } from "utils/functional";
export const MincaModal = ({
  title = "",
  isOpen,
  onRequestClose,
  children,
  isFullScreen = false,
  disableClickOutToClose = false,
  hideModalChrome = false,
  className: classNameProp = "",
  width: widthType = "content"
}: {
  // If the modal should be open.
  isOpen: boolean;

  // The method to call to close the dialog
  // Leaving empty will:
  //   * disable esc to close
  //   * omit the close button
  //   * disable click-out to close
  onRequestClose?: () => void;

  // Disable click-out to close.  Does nothing if onRequestClose is undefined.
  disableClickOutToClose?: boolean;

  // This will go in the title slot, leave empty to not have a title slot.
  title?: ReactNode;

  // Create a scrollable viewport that is a bit smaller than the full screen
  // It'll still look like a modal (some content visible behind), but have
  // a fixed height/width to take up most of the screen.
  isFullScreen?: boolean;

  // The content of the modal
  children: React.ReactNode;

  // Set to `true` to disable showing the title bar and the close icon;
  hideModalChrome?: boolean;

  // Class names for the modal container
  className?: string;

  // How big should the modal be, no effect if `isFullScreen` is true
  width?: "content" | "small" | "medium";
}) => {
  useLayoutEffect(() => {
    ReactModal.setAppElement("#app-root");
  }, []);
  const widthClassNames = isFullScreen ? "h-full w-[calc(100%-80px)] min-w-[280px]" : widthType === "content" ? "w-fit max-w-[calc(100%-80px)]" : widthType === "small" ? "sm:w-[560px] w-[calc(100%-80px)]" : widthType === "medium" ? "md:w-[688px] w-[calc(100%-80px)]" : throwExpression("Unknown width type"); // Should never happen b/c of TS.

  return <ReactModal isOpen={isOpen} onRequestClose={e => {
    e.stopPropagation();
    onRequestClose?.();
  }} shouldCloseOnOverlayClick={!disableClickOutToClose} shouldCloseOnEsc={!disableClickOutToClose} contentLabel={isPresent(title) ? title.toString() : undefined} overlayClassName={classNames("fixed", "inset-0", "z-[1000]", "overflow-y-auto", "bg-[rgba(0,0,0,0.5)]", "h-full", "flex flex-col justify-center")} className={classNamesWithReset(classNames("flex flex-col", "z-[100]", "max-h-[100vh]", "h-fit", "mx-auto", "my-auto", "p-0", "border-0", "rounded-md", "bg-white", "overflow-y-visible", "min-w-[280px]", widthClassNames), classNameProp)} data-sentry-element="ReactModal" data-sentry-component="MincaModal" data-sentry-source-file="MincaModal.tsx">
      <div className={classNames("modal-content-container", "relative", "w-full", "rounded-[10px]", "h-fit", "flex", "flex-col", "overflow-visible", "bg-white")} onClick={e => {
      e.stopPropagation();
    }}>
        {(!!onRequestClose || isPresent(title)) && !hideModalChrome && <div className="flex flex-row items-end justify-between gap-4">
            {title && <PageHeading className="!p-0 flex-1 flex flex-row justify-center items-center !mb-[-4px] !mt-[6px] flex-grow mx-[30px] sm:mx-[40px]">
                {/* Wrap the title in a span if it's just text */}
                {typeof title === "string" ? <span>{title}</span> : title}
              </PageHeading>}
            {!!onRequestClose && <button onClick={onRequestClose} className="focus:outline-none hover:text-gray-300 focus:text-gray-100 !my-0 !mx-0 !p-0 absolute right-1 top-0.5 sm:right-2 sm:top-2">
                <XMarkIcon className="size-4 md:size-6" />
              </button>}
          </div>}

        <div className={classNames("modal-content-container-inner", "sm:px-6", "sm:py-4", "px-2", "py-2", isFullScreen ? "h-full flex-grow" : undefined)}>
          {children}
        </div>
      </div>
    </ReactModal>;
};