import { classNames, classNamesWithReset } from "utils";
import { HasChildren, HasClassName } from "../LayoutFeatures";
export const ContentArea = ({
  className = "",
  children
}: HasClassName & HasChildren) => {
  return <div className={`content-area w-full ${className}`} data-sentry-component="ContentArea" data-sentry-source-file="ContentArea.tsx">{children}</div>;
};
export const DrawerContentArea = ({
  className: classNameProp = "",
  children
}: HasClassName & HasChildren) => {
  const className = classNamesWithReset("my-5 mx-5", classNameProp);
  return <ContentArea className={className} data-sentry-element="ContentArea" data-sentry-component="DrawerContentArea" data-sentry-source-file="ContentArea.tsx">{children}</ContentArea>;
};
export const ModalContentArea = ({
  className: classNameProp = "",
  children
}: HasClassName & HasChildren) => {
  const className = classNamesWithReset(classNames("modal-content-area", "mx-0", "mb-0", "mt-1"), classNameProp);
  return <ContentArea className={className} data-sentry-element="ContentArea" data-sentry-component="ModalContentArea" data-sentry-source-file="ContentArea.tsx">{children}</ContentArea>;
};